import {DrupalJsonApiParams} from "drupal-jsonapi-params";
import {drupal} from "@/lib/drupal";
import {DrupalMenuLinkContent, DrupalNode, DrupalTaxonomyTerm} from "next-drupal";
import {format} from "date-fns";

export const getPodcast = async (context, limit = 4) => {

  const dateJ = format(new Date(), 't')

  const paramsPromoted = new DrupalJsonApiParams()
  .addFields("node--podcast", ["title", "path", "field_media_illustration", "uid", "created", "field_thematique", "field_fichier", "field_animateurs", "body", "field_fichier_video"])
  .addFilter("status", "1")
  .addFilter("field_date", dateJ, '<=')
  .addInclude([
    "field_media_illustration",
    "field_media_illustration.field_media_image",
    "field_fichier",
    "field_fichier.field_media_audio_file",
    "field_thematique",
    "field_animateurs",
    "field_animateurs.field_image",
    "uid",
    "field_fichier_video"
  ])
  .addSort("field_date", "DESC")
  .addPageLimit(limit)

  return await drupal.getResourceCollectionFromContext<DrupalNode[]>(
    "node--podcast",
    context,
    {
      params: paramsPromoted.getQueryObject(),
      deserialize: false,
    }
  )
}

export const getPromotedPodcasts = async (context, limit = 4) => {

  const dateJ = format(new Date(), 't')

  const paramsPromoted = new DrupalJsonApiParams()
    .addFields("node--podcast", ["title", "path", "field_media_illustration", "uid", "created", "field_thematique", "field_fichier", "field_animateurs", "body", "field_fichier_video"])
    .addFilter("status", "1")
    .addFilter("field_date", dateJ, '<=')
    .addFilter("field_mis_en_avant", '1')
    .addInclude([
      "field_media_illustration",
      "field_media_illustration.field_media_image",
      "field_fichier",
      "field_fichier.field_media_audio_file",
      "field_thematique",
      "field_animateurs",
      "field_animateurs.field_image",
    "uid",
      "field_fichier_video"
  ])
    .addSort("created", "DESC")
    .addPageLimit(limit)

  return await drupal.getResourceCollectionFromContext<DrupalNode[]>(
    "node--podcast",
    context,
    {
      params: paramsPromoted.getQueryObject(),
      deserialize: false,
    }
  )
}

export const getLatestPodcast = async (context, limit = 4) => {
  const dateJ = format(new Date(), 't')
  const paramsPodcasts = new DrupalJsonApiParams()
    .addFields("node--podcast", ["title", "body", "path", "field_media_illustration", "field_thematique", "field_fichier.field_media_audio_file", "field_fichier.field_google_storage_link", "created", "field_date", "field_mis_en_avant"])
    .addFilter("status", "1")
    .addFilter("field_date", dateJ, "<")
    .addFilter("field_mis_en_avant", "1")
    .addInclude(["uid", "field_media_illustration", "field_media_illustration.field_media_image", "field_thematique"])
    .addSort("created", "DESC")
    .addPageLimit(limit)
  return await drupal.getResourceCollectionFromContext<DrupalNode[]>(
    "node--podcast",
    context,
    {
      params: paramsPodcasts.getQueryObject(),
      deserialize: false,
    }
  )
}

export const getSearchedPodcasts = async (search, thematique, page) => {

  const dateJ = format(new Date(), 't')

  const view = await drupal.getView<DrupalNode[]>(
    "skazy_podcasts--listing",
    {
      params:
        {
          'views-filter': {
            title: search === '' ? undefined : search,
            field_date: dateJ,
            field_thematique: thematique === '' ? undefined : thematique,
          },
          page: (page - 1),
          include: 'field_media_illustration,field_media_illustration.field_media_image,field_fichier,field_fichier.field_media_audio_file,field_fichier.field_google_storage_link,field_image_archive'
        },
      deserialize: false,
    }
  )

  return view.results
}

export const getFavorites = async (context, limit = 4) => {

  const params = new DrupalJsonApiParams()
  .addFields("node--coups_de_coeur_rrb", ["uid", "title", "field_soustitre", "field_categorie", "field_media_illustration", "field_lien"])
  .addFilter("status", "1")
  .addInclude([
    "uid",
    "field_categorie",
    "field_media_illustration.field_media_image",
  ])
  .addSort("created", "DESC")
  .addPageLimit(limit)

  return await drupal.getResourceCollectionFromContext<DrupalNode[]>(
    "node--coups_de_coeur_rrb",
    context,
    {
      params: params.getQueryObject(),
      deserialize: false,
    }
  )
}

export const getLatestArticle = async (context, limit = 4) => {
  const params = new DrupalJsonApiParams()
    .addFields("node--article", ["title", "path", "field_media_illustration", "field_contenu_structure", "field_thematique", "field_sous_titre", "created"])
    .addFilter("status", "1")
    .addInclude(["uid", "field_media_illustration", "field_media_illustration.field_media_image", "field_contenu_structure", "field_thematique"])
    .addSort("created", "DESC")
    .addPageLimit(limit)

  return await drupal.getResourceCollectionFromContext<DrupalNode[]>(
    "node--article",
    context,
    {
      params: params.getQueryObject(),
      deserialize: false,
    }
  )
}

export const getMenuItems = async (url: string)  => {
  try {
    const { items } = await drupal.getMenu<DrupalMenuLinkContent>(url)
    return items
  } catch (error) {
    console.log(error)
  }
}

export const getTeamMembers = async (context) => {

  const params = new DrupalJsonApiParams()
  .addFields("node--equipe_rrb", ["title", "path", "field_image", "field_roles", "created", "field_order_by"])
  .addFilter("status", "1")
  .addInclude(["uid", "field_image", "field_roles", "field_roles.parent"])
  .addSort("field_order_by", "DESC")

  return await drupal.getResourceCollectionFromContext<DrupalNode[]>(
    "node--equipe_rrb",
    context,
    {
      params: params.getQueryObject(),
      deserialize: false,
    }
  )
}

export const getUpcomingPodcasts = async (context, limit = 2) => {

  const dateJ = format(new Date(), 't')

  const paramsUpcoming = new DrupalJsonApiParams()
    .addFields("node--podcast", ["title", "path", "field_media_illustration", "uid", "created", "field_thematique", "field_fichier", "field_animateurs", "body", "field_fichier_video", "field_date", "field_mis_en_avant"])
    .addFilter("status", "1")
    .addFilter("field_date", dateJ, '>')
    .addFilter("field_mis_en_avant", '1')
    .addInclude([
      "field_media_illustration",
      "field_media_illustration.field_media_image",
      "field_fichier",
      "field_fichier.field_media_audio_file",
      "field_thematique",
      "field_animateurs",
      "uid",
      "field_fichier_video"])
    .addSort("created", "DESC")
    .addPageLimit(limit)

  return await drupal.getResourceCollectionFromContext<DrupalNode[]>(
    "node--podcast",
    context,
    {
      params: paramsUpcoming.getQueryObject(),
      deserialize: false,
    }
  )
}

export const getEmissions = async (context, limit = 12, offset = 0) => {
  const paramsEmissions = new DrupalJsonApiParams()
    .addFields("node--emission", ["title", "path", "field_media_illustration", "uid", "created", "field_animateurs"])
    .addFilter("status", "1")
    .addFilter("field_hidden", "0")
    .addInclude(["field_media_illustration", "field_media_illustration.field_media_image", "field_animateurs", "uid"])
    .addSort("field_order_by", "ASC")
    .addPageLimit(limit);

  if (limit > 0) {
    paramsEmissions.addPageOffset(offset)
  }

  // Récupération de la liste des émissions
  return await drupal.getResourceCollectionFromContext<DrupalNode[]>(
    "node--emission",
    context,
    {
      params: paramsEmissions.getQueryObject(),
      deserialize: false,
    }
  )
}

export const getTermThematique = async (context) => {
  return await drupal.getResourceCollectionFromContext<DrupalTaxonomyTerm[]>(
    "taxonomy_term--thematiques",
    context,
    {
      params: {
        "filter[status]": 1
      }
    }
  )
}

export const getSimilarPodcastAndArticle = async (type: string, resource: DrupalNode) => {
  let similarResources: DrupalNode[] = [];

  if (type === "node--article" || type === "node--podcast") {
    const view = await drupal.getView<DrupalNode[]>(
      "similar_podcast_and_article--main",
      {
        params:
          {
            'views-filter': {
              nid: resource.drupal_internal__nid,
              field_thematique: resource.field_thematique.map(
                (thematic: DrupalTaxonomyTerm) => thematic.drupal_internal__tid
              )
            },
            include: 'field_media_illustration,field_media_illustration.field_media_image,field_thematique'
          }
      }
    )

    similarResources = view.results
  }

  return similarResources
}

export const getArticlePodcastHighlight = async () => {
  const view = await drupal.getView<DrupalNode[]>(
    "skazy_contenus_mis_en_avant--article_podcast",
    {
      params:
        {
          include: 'field_media_illustration,field_media_illustration.field_media_image,field_fichier,field_fichier.field_media_audio_file,field_fichier.field_google_storage_link,field_image_archive'
        },
      deserialize: false,
    }
  )
  
  return view.results
}
